import {Component, Injectable, TemplateRef, ViewContainerRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StripHtmlPipe} from './strip-html.pipe';
import {SafeHtml} from '@angular/platform-browser';
import {CloseReason} from './close-reason.enum'
import {BehaviorSubject} from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    styleUrls: ['modal-content.scss'],
    template: `
    <h4 class="card-title p-4">
      {{title}}
      <button type="button" *ngIf="mode!==DialogMode.CHOOSE" class="close pull-right" aria-label="Close"
              (click)="activeModal.dismiss(CloseReason.CLOSE)">
        <span aria-hidden="true">&times;</span>
      </button>
    </h4>
    <div class="pt-4"></div>
    <p class="card-text px-4" [innerHtml]="message" *ngIf="message"></p>
    <div *ngIf="showInput" class="px-4">
      <textarea type="text" [(ngModel)]="inputText" name="inputText" class="form-control"> </textarea>
    </div>
    <p class="list-group px-4" *ngIf="options">
      <button *ngFor="let option of options" class="list-group-item list-group-item-action"
              (click)="activeModal.close(option)" style="cursor: pointer">
        {{option}}
      </button>
    </p>
    <div class="card-text px-4" *ngIf="error">
      <pre><code [innerHtml]="error"></code></pre>
    </div>
    <div class="px-4">
      <div #templatecontainer></div>
    </div>
    <div class="p-4 text-end" *ngIf="mode!==DialogMode.CHOOSE">
      <ng-container *ngIf="mode===DialogMode.CONFIRM">
        <button type="button" class="btn btn-primary me-1"
                (click)="activeModal.close(CloseReason.CONFIRM)">OK
        </button>
        <button type="button" class="btn btn-outline-secondary"
                (click)="activeModal.close(CloseReason.CANCEL)">Cancel
        </button>
      </ng-container>
      <ng-container *ngIf="mode===DialogMode.NOTIFICATION">
        <button type="button" class="btn btn-outline-secondary"
                (click)="activeModal.close(CloseReason.CLOSE)">Close
        </button>
      </ng-container>
    </div>
  `,
    standalone: true,
    imports: [NgIf, FormsModule, NgFor]
})
export class DialogContentComponent {
  title = 'Error';
  message: string | SafeHtml = null;
  error = '';
  mode = DialogMode.NOTIFICATION;
  showInput = false;
  inputText = '';
  options: string[] = null;
  CloseReason = CloseReason;
  DialogMode = DialogMode;
  @ViewChild('templatecontainer', {static: true, read: ViewContainerRef})
  templateContainer: ViewContainerRef;

  constructor(public activeModal: NgbActiveModal) {
  }
}

enum DialogMode {
  NOTIFICATION, CONFIRM, CHOOSE
}

class ConfirmDialogWithText {
  constructor(public confirmed: boolean, public text: string) {
  }
}

interface ToastNotification {
  message: string;
  title?: string;
  type: 'info' | 'error' | 'success' | 'warning';
  autoHideMs?: number;
}

const timeouts = {
  error: 10000,
  warning: 10000,
  info: 5000,
  success: 5000
}

@Injectable()
export class NotificationService {
  public toastNotifications$ = new BehaviorSubject<ToastNotification[]>([]);

  constructor(private modalService: NgbModal) {
  }

  notify(message: string, title: string = 'Info', type: 'info' | 'error' | 'success' | 'warning' = 'info') {
    const toast: ToastNotification = {message, title, type, autoHideMs: timeouts[type]};
    this.toastNotifications$.next([...this.toastNotifications$.value, toast]);
  }

  error(message: string | SafeHtml, error: string) {
    this.clearFocus();
    const dialog = this.modalService.open(DialogContentComponent);
    dialog.componentInstance.title = 'Error';
    dialog.componentInstance.error = StripHtmlPipe.extractBody(error);
    dialog.componentInstance.message = message;
  }

  success(message: string, title = 'Success') {
    this.clearFocus();
    const dialog = this.modalService.open(DialogContentComponent);
    dialog.componentInstance.message = message;
    dialog.componentInstance.title = title;
  }

  /**
   *
   * @param message
   * @param title
   * @returns {Promise<boolean>} Promise, true if dialog is confirmed
   */
  confirm(message: string, title: string) {
    this.clearFocus();
    const dialog = this.modalService.open(DialogContentComponent);
    dialog.componentInstance.mode = DialogMode.CONFIRM;
    dialog.componentInstance.message = message;
    dialog.componentInstance.title = title;

    return new Promise<boolean>((resolve) => {
      dialog.result
        .then(result => resolve(result === CloseReason.CONFIRM))
        .catch(() => resolve(false));
    });
  }

  /**
   *
   * @param message
   * @param title
   * @param showInput
   * @returns {Promise<boolean>} Promise, true if dialog is confirmed
   */
  confirmWithText(message: string, title: string, showInput = false): Promise<ConfirmDialogWithText> {
    this.clearFocus();
    const dialog = this.modalService.open(DialogContentComponent);
    dialog.componentInstance.mode = DialogMode.CONFIRM;
    dialog.componentInstance.message = message;
    dialog.componentInstance.title = title;
    dialog.componentInstance.showInput = showInput;

    const instance = dialog.componentInstance;

    return new Promise<ConfirmDialogWithText>((resolve) => {
      dialog.result
        .then(result => resolve(new ConfirmDialogWithText(result === CloseReason.CONFIRM, instance.inputText)))
        .catch(() => resolve(new ConfirmDialogWithText(false, instance.inputText)));
    });
  }


  choose(title: string, message: string, options: string[]) {
    this.clearFocus();
    const dialog = this.modalService.open(DialogContentComponent, {keyboard: false, backdrop: 'static'});
    dialog.componentInstance.mode = DialogMode.CHOOSE;
    dialog.componentInstance.message = message;
    dialog.componentInstance.title = title;
    dialog.componentInstance.options = options;

    return dialog.result;
  }

  forTemplate(title: string, template: TemplateRef<any>, additionalContext: any = {}, mode: DialogMode = DialogMode.NOTIFICATION) {
    this.clearFocus();
    const dialog = this.modalService.open(DialogContentComponent);
    dialog.componentInstance.mode = mode;
    dialog.componentInstance.title = title;
    const context = {
      ...additionalContext,
      close: (reason?: any) => dialog.close(reason),
      dismiss: (reason?: any) => dialog.dismiss(reason)
    };
    dialog.componentInstance.templateContainer.createEmbeddedView(template, context);

    return dialog.result;
  }

  private clearFocus() {
    // see https://github.com/ng-bootstrap/ng-bootstrap/issues/1252
    (<any>document.activeElement).blur();
  }

  removeToastNotification(toast: ToastNotification) {
    this.toastNotifications$.next(this.toastNotifications$.getValue().filter(t => t !== toast));
  }
}
