/**
 * Cloud Backup for Podio API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LevelApiModel { 
    max?: number;
    current?: number;
    scope?: LevelApiModel.ScopeEnum;
    name?: string;
}
export namespace LevelApiModel {
    export type ScopeEnum = 'global' | 'org' | 'space' | 'app' | 'contacts' | 'tasks' | 'items' | 'files' | 'excel';
    export const ScopeEnum = {
        Global: 'global' as ScopeEnum,
        Org: 'org' as ScopeEnum,
        Space: 'space' as ScopeEnum,
        App: 'app' as ScopeEnum,
        Contacts: 'contacts' as ScopeEnum,
        Tasks: 'tasks' as ScopeEnum,
        Items: 'items' as ScopeEnum,
        Files: 'files' as ScopeEnum,
        Excel: 'excel' as ScopeEnum
    };
}


