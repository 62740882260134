import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'stripHtml',
    standalone: true
})
export class StripHtmlPipe implements PipeTransform {

    /**
     * If text contains html body, the contents is extracted - otherwise original text is returned.
     */
    static extractBody(text: string) {
        if (!text) {
            return text;
        }
        const iBody = text.indexOf('<body>');
        if (iBody >= 0 && text.indexOf('</body>') > iBody) {
            return text.substr(iBody + 6, text.indexOf('</body>') - iBody);
        } else {
            return text;
        }
    }

    transform(value: any, args?: any): any {
        return StripHtmlPipe.extractBody(value);
    }

}
