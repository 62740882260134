/**
 * Cloud Backup for Podio API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BackupAutoDeleteConfigApiModel } from '../model/backupAutoDeleteConfig';
// @ts-ignore
import { BackupCollectionConfigApiModel } from '../model/backupCollectionConfig';
// @ts-ignore
import { BackupIterationApiModel } from '../model/backupIteration';
// @ts-ignore
import { CopyAppRequestApiModel } from '../model/copyAppRequest';
// @ts-ignore
import { CopyItemRequestApiModel } from '../model/copyItemRequest';
// @ts-ignore
import { CopyItemsBatchRequestApiModel } from '../model/copyItemsBatchRequest';
// @ts-ignore
import { CopyNamespaceApiModel } from '../model/copyNamespace';
// @ts-ignore
import { CreateBackupCollection200ResponseApiModel } from '../model/createBackupCollection200Response';
// @ts-ignore
import { FileLinkApiModel } from '../model/fileLink';
// @ts-ignore
import { GetBackupCollection200ResponseApiModel } from '../model/getBackupCollection200Response';
// @ts-ignore
import { PodioContactApiModel } from '../model/podioContact';
// @ts-ignore
import { PodioCredentialsApiModel } from '../model/podioCredentials';
// @ts-ignore
import { PodioCredentialsValidationApiModel } from '../model/podioCredentialsValidation';
// @ts-ignore
import { PodioItemApiModel } from '../model/podioItem';
// @ts-ignore
import { PodioTaskApiModel } from '../model/podioTask';
// @ts-ignore
import { RecurrenceApiModel } from '../model/recurrence';
// @ts-ignore
import { RestoreItemsBatchRequestApiModel } from '../model/restoreItemsBatchRequest';
// @ts-ignore
import { RestoreTask200ResponseApiModel } from '../model/restoreTask200Response';
// @ts-ignore
import { RestoredAppResultApiModel } from '../model/restoredAppResult';
// @ts-ignore
import { RestoredItemReferenceApiModel } from '../model/restoredItemReference';
// @ts-ignore
import { TaskApiModel } from '../model/task';
// @ts-ignore
import { TreeNodeApiModel } from '../model/treeNode';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BackupService {

    protected basePath = 'https://test.cloud-backup-for-podio.com';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param copyAppRequestApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RestoredAppResultApiModel>;
    public copyApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RestoredAppResultApiModel>>;
    public copyApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RestoredAppResultApiModel>>;
    public copyApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling copyApp.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling copyApp.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling copyApp.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling copyApp.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling copyApp.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/copy`;
        return this.httpClient.request<RestoredAppResultApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: copyAppRequestApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param copyAppRequestApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RestoredAppResultApiModel>;
    public copyAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RestoredAppResultApiModel>>;
    public copyAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RestoredAppResultApiModel>>;
    public copyAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyAppRequestApiModel?: CopyAppRequestApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling copyAppAndItems.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling copyAppAndItems.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling copyAppAndItems.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling copyAppAndItems.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling copyAppAndItems.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/copy_app_and_items`;
        return this.httpClient.request<RestoredAppResultApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: copyAppRequestApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param item 
     * @param copyItemRequestApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, copyItemRequestApiModel?: CopyItemRequestApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RestoredItemReferenceApiModel>;
    public copyItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, copyItemRequestApiModel?: CopyItemRequestApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RestoredItemReferenceApiModel>>;
    public copyItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, copyItemRequestApiModel?: CopyItemRequestApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RestoredItemReferenceApiModel>>;
    public copyItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, copyItemRequestApiModel?: CopyItemRequestApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling copyItem.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling copyItem.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling copyItem.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling copyItem.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling copyItem.');
        }
        if (item === null || item === undefined) {
            throw new Error('Required parameter item was null or undefined when calling copyItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/item/${this.configuration.encodeParam({name: "item", value: item, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/copy`;
        return this.httpClient.request<RestoredItemReferenceApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: copyItemRequestApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param copyItemsBatchRequestApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyItemsBatchRequestApiModel?: CopyItemsBatchRequestApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public copyItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyItemsBatchRequestApiModel?: CopyItemsBatchRequestApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public copyItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyItemsBatchRequestApiModel?: CopyItemsBatchRequestApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public copyItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, copyItemsBatchRequestApiModel?: CopyItemsBatchRequestApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling copyItemsBatch.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling copyItemsBatch.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling copyItemsBatch.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling copyItemsBatch.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling copyItemsBatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/copy_items`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: copyItemsBatchRequestApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupCollectionConfigApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBackupCollection(backupcollection: string, backupCollectionConfigApiModel?: BackupCollectionConfigApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CreateBackupCollection200ResponseApiModel>;
    public createBackupCollection(backupcollection: string, backupCollectionConfigApiModel?: BackupCollectionConfigApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CreateBackupCollection200ResponseApiModel>>;
    public createBackupCollection(backupcollection: string, backupCollectionConfigApiModel?: BackupCollectionConfigApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CreateBackupCollection200ResponseApiModel>>;
    public createBackupCollection(backupcollection: string, backupCollectionConfigApiModel?: BackupCollectionConfigApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling createBackupCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/create`;
        return this.httpClient.request<CreateBackupCollection200ResponseApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: backupCollectionConfigApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBackupIteration(backupcollection: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public createBackupIteration(backupcollection: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public createBackupIteration(backupcollection: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public createBackupIteration(backupcollection: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling createBackupIteration.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBackupCollection(backupcollection: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteBackupCollection(backupcollection: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteBackupCollection(backupcollection: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteBackupCollection(backupcollection: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling deleteBackupCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBackupCollectionCredentials(backupcollection: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteBackupCollectionCredentials(backupcollection: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteBackupCollectionCredentials(backupcollection: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteBackupCollectionCredentials(backupcollection: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling deleteBackupCollectionCredentials.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/credentials`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBackupIteration(backupcollection: string, backupiteration: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteBackupIteration(backupcollection: string, backupiteration: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteBackupIteration(backupcollection: string, backupiteration: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteBackupIteration(backupcollection: string, backupiteration: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling deleteBackupIteration.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling deleteBackupIteration.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRecurrence(backupcollection: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteRecurrence(backupcollection: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteRecurrence(backupcollection: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteRecurrence(backupcollection: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling deleteRecurrence.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/recurrence`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCommentsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public exportCommentsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public exportCommentsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public exportCommentsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling exportCommentsCsv.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling exportCommentsCsv.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling exportCommentsCsv.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling exportCommentsCsv.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling exportCommentsCsv.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling exportCommentsCsv.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/exportcomments/${this.configuration.encodeParam({name: "filename", value: filename, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportCommentsTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public exportCommentsTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public exportCommentsTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public exportCommentsTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling exportCommentsTasksCsv.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling exportCommentsTasksCsv.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling exportCommentsTasksCsv.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling exportCommentsTasksCsv.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling exportCommentsTasksCsv.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling exportCommentsTasksCsv.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/export_comments_tasks/${this.configuration.encodeParam({name: "filename", value: filename, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportItemsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public exportItemsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public exportItemsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public exportItemsCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling exportItemsCsv.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling exportItemsCsv.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling exportItemsCsv.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling exportItemsCsv.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling exportItemsCsv.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling exportItemsCsv.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/exportitems/${this.configuration.encodeParam({name: "filename", value: filename, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param filename 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public exportTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public exportTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public exportTasksCsv(backupcollection: string, backupiteration: string, org: string, space: string, app: string, filename: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/csv', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling exportTasksCsv.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling exportTasksCsv.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling exportTasksCsv.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling exportTasksCsv.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling exportTasksCsv.');
        }
        if (filename === null || filename === undefined) {
            throw new Error('Required parameter filename was null or undefined when calling exportTasksCsv.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/csv'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/export_tasks/${this.configuration.encodeParam({name: "filename", value: filename, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBackupCollection(backupcollection: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<GetBackupCollection200ResponseApiModel>;
    public getBackupCollection(backupcollection: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetBackupCollection200ResponseApiModel>>;
    public getBackupCollection(backupcollection: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetBackupCollection200ResponseApiModel>>;
    public getBackupCollection(backupcollection: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getBackupCollection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GetBackupCollection200ResponseApiModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBackupIteration(backupcollection: string, backupiteration: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BackupIterationApiModel>;
    public getBackupIteration(backupcollection: string, backupiteration: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BackupIterationApiModel>>;
    public getBackupIteration(backupcollection: string, backupiteration: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BackupIterationApiModel>>;
    public getBackupIteration(backupcollection: string, backupiteration: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getBackupIteration.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getBackupIteration.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<BackupIterationApiModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get navigation hierarchy of all backups
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBackupTree(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<TreeNodeApiModel>>;
    public getBackupTree(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<TreeNodeApiModel>>>;
    public getBackupTree(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<TreeNodeApiModel>>>;
    public getBackupTree(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/gui/tree`;
        return this.httpClient.request<Array<TreeNodeApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContacts(backupcollection: string, backupiteration: string, org: string, space: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<PodioContactApiModel>>;
    public getContacts(backupcollection: string, backupiteration: string, org: string, space: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<PodioContactApiModel>>>;
    public getContacts(backupcollection: string, backupiteration: string, org: string, space: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<PodioContactApiModel>>>;
    public getContacts(backupcollection: string, backupiteration: string, org: string, space: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getContacts.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getContacts.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getContacts.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling getContacts.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/contacts`;
        return this.httpClient.request<Array<PodioContactApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCopyNamespaces(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<CopyNamespaceApiModel>>;
    public getCopyNamespaces(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<CopyNamespaceApiModel>>>;
    public getCopyNamespaces(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<CopyNamespaceApiModel>>>;
    public getCopyNamespaces(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getCopyNamespaces.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getCopyNamespaces.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getCopyNamespaces.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling getCopyNamespaces.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling getCopyNamespaces.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/copy_namespaces`;
        return this.httpClient.request<Array<CopyNamespaceApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param all 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilesForApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, all?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FileLinkApiModel>>;
    public getFilesForApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, all?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FileLinkApiModel>>>;
    public getFilesForApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, all?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FileLinkApiModel>>>;
    public getFilesForApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, all?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getFilesForApp.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getFilesForApp.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getFilesForApp.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling getFilesForApp.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling getFilesForApp.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (all !== undefined && all !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>all, 'all');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/files`;
        return this.httpClient.request<Array<FileLinkApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param all 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilesForBackupCollection(backupcollection: string, all?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FileLinkApiModel>>;
    public getFilesForBackupCollection(backupcollection: string, all?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FileLinkApiModel>>>;
    public getFilesForBackupCollection(backupcollection: string, all?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FileLinkApiModel>>>;
    public getFilesForBackupCollection(backupcollection: string, all?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getFilesForBackupCollection.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (all !== undefined && all !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>all, 'all');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/files`;
        return this.httpClient.request<Array<FileLinkApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param item 
     * @param all 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilesForItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, all?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FileLinkApiModel>>;
    public getFilesForItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, all?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FileLinkApiModel>>>;
    public getFilesForItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, all?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FileLinkApiModel>>>;
    public getFilesForItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, all?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getFilesForItem.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getFilesForItem.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getFilesForItem.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling getFilesForItem.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling getFilesForItem.');
        }
        if (item === null || item === undefined) {
            throw new Error('Required parameter item was null or undefined when calling getFilesForItem.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (all !== undefined && all !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>all, 'all');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/item/${this.configuration.encodeParam({name: "item", value: item, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/files`;
        return this.httpClient.request<Array<FileLinkApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param all 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilesForIteration(backupcollection: string, backupiteration: string, all?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FileLinkApiModel>>;
    public getFilesForIteration(backupcollection: string, backupiteration: string, all?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FileLinkApiModel>>>;
    public getFilesForIteration(backupcollection: string, backupiteration: string, all?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FileLinkApiModel>>>;
    public getFilesForIteration(backupcollection: string, backupiteration: string, all?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getFilesForIteration.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getFilesForIteration.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (all !== undefined && all !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>all, 'all');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/files`;
        return this.httpClient.request<Array<FileLinkApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param all 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilesForOrg(backupcollection: string, backupiteration: string, org: string, all?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FileLinkApiModel>>;
    public getFilesForOrg(backupcollection: string, backupiteration: string, org: string, all?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FileLinkApiModel>>>;
    public getFilesForOrg(backupcollection: string, backupiteration: string, org: string, all?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FileLinkApiModel>>>;
    public getFilesForOrg(backupcollection: string, backupiteration: string, org: string, all?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getFilesForOrg.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getFilesForOrg.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getFilesForOrg.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (all !== undefined && all !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>all, 'all');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/files`;
        return this.httpClient.request<Array<FileLinkApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param all 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFilesForSpace(backupcollection: string, backupiteration: string, org: string, space: string, all?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FileLinkApiModel>>;
    public getFilesForSpace(backupcollection: string, backupiteration: string, org: string, space: string, all?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FileLinkApiModel>>>;
    public getFilesForSpace(backupcollection: string, backupiteration: string, org: string, space: string, all?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FileLinkApiModel>>>;
    public getFilesForSpace(backupcollection: string, backupiteration: string, org: string, space: string, all?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getFilesForSpace.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getFilesForSpace.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getFilesForSpace.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling getFilesForSpace.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (all !== undefined && all !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>all, 'all');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/files`;
        return this.httpClient.request<Array<FileLinkApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param item 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getItemDetails(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PodioItemApiModel>;
    public getItemDetails(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PodioItemApiModel>>;
    public getItemDetails(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PodioItemApiModel>>;
    public getItemDetails(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getItemDetails.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getItemDetails.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getItemDetails.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling getItemDetails.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling getItemDetails.');
        }
        if (item === null || item === undefined) {
            throw new Error('Required parameter item was null or undefined when calling getItemDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/item/${this.configuration.encodeParam({name: "item", value: item, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<PodioItemApiModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<PodioItemApiModel>>;
    public getItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<PodioItemApiModel>>>;
    public getItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<PodioItemApiModel>>>;
    public getItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getItems.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getItems.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling getItems.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling getItems.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling getItems.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/item`;
        return this.httpClient.request<Array<PodioItemApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param podioid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskDetails(backupcollection: string, backupiteration: string, podioid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PodioTaskApiModel>;
    public getTaskDetails(backupcollection: string, backupiteration: string, podioid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PodioTaskApiModel>>;
    public getTaskDetails(backupcollection: string, backupiteration: string, podioid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PodioTaskApiModel>>;
    public getTaskDetails(backupcollection: string, backupiteration: string, podioid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getTaskDetails.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getTaskDetails.');
        }
        if (podioid === null || podioid === undefined) {
            throw new Error('Required parameter podioid was null or undefined when calling getTaskDetails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/podioid/${this.configuration.encodeParam({name: "podioid", value: podioid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<PodioTaskApiModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTasks(backupcollection: string, backupiteration: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<TaskApiModel>>;
    public getTasks(backupcollection: string, backupiteration: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<TaskApiModel>>>;
    public getTasks(backupcollection: string, backupiteration: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<TaskApiModel>>>;
    public getTasks(backupcollection: string, backupiteration: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling getTasks.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling getTasks.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/tasks`;
        return this.httpClient.request<Array<TaskApiModel>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RestoredAppResultApiModel>;
    public restoreApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RestoredAppResultApiModel>>;
    public restoreApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RestoredAppResultApiModel>>;
    public restoreApp(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling restoreApp.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling restoreApp.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling restoreApp.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling restoreApp.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling restoreApp.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/restore_app`;
        return this.httpClient.request<RestoredAppResultApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RestoredAppResultApiModel>;
    public restoreAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RestoredAppResultApiModel>>;
    public restoreAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RestoredAppResultApiModel>>;
    public restoreAppAndItems(backupcollection: string, backupiteration: string, org: string, space: string, app: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling restoreAppAndItems.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling restoreAppAndItems.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling restoreAppAndItems.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling restoreAppAndItems.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling restoreAppAndItems.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/restore_app_and_items`;
        return this.httpClient.request<RestoredAppResultApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param item 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RestoredItemReferenceApiModel>;
    public restoreItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RestoredItemReferenceApiModel>>;
    public restoreItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RestoredItemReferenceApiModel>>;
    public restoreItem(backupcollection: string, backupiteration: string, org: string, space: string, app: string, item: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling restoreItem.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling restoreItem.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling restoreItem.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling restoreItem.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling restoreItem.');
        }
        if (item === null || item === undefined) {
            throw new Error('Required parameter item was null or undefined when calling restoreItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/item/${this.configuration.encodeParam({name: "item", value: item, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/restore`;
        return this.httpClient.request<RestoredItemReferenceApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param org 
     * @param space 
     * @param app 
     * @param restoreItemsBatchRequestApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, restoreItemsBatchRequestApiModel?: RestoreItemsBatchRequestApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public restoreItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, restoreItemsBatchRequestApiModel?: RestoreItemsBatchRequestApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public restoreItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, restoreItemsBatchRequestApiModel?: RestoreItemsBatchRequestApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public restoreItemsBatch(backupcollection: string, backupiteration: string, org: string, space: string, app: string, restoreItemsBatchRequestApiModel?: RestoreItemsBatchRequestApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling restoreItemsBatch.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling restoreItemsBatch.');
        }
        if (org === null || org === undefined) {
            throw new Error('Required parameter org was null or undefined when calling restoreItemsBatch.');
        }
        if (space === null || space === undefined) {
            throw new Error('Required parameter space was null or undefined when calling restoreItemsBatch.');
        }
        if (app === null || app === undefined) {
            throw new Error('Required parameter app was null or undefined when calling restoreItemsBatch.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/org/${this.configuration.encodeParam({name: "org", value: org, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/space/${this.configuration.encodeParam({name: "space", value: space, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/app/${this.configuration.encodeParam({name: "app", value: app, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/restore_items`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: restoreItemsBatchRequestApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupiteration 
     * @param taskid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreTask(backupcollection: string, backupiteration: string, taskid: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RestoreTask200ResponseApiModel>;
    public restoreTask(backupcollection: string, backupiteration: string, taskid: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RestoreTask200ResponseApiModel>>;
    public restoreTask(backupcollection: string, backupiteration: string, taskid: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RestoreTask200ResponseApiModel>>;
    public restoreTask(backupcollection: string, backupiteration: string, taskid: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling restoreTask.');
        }
        if (backupiteration === null || backupiteration === undefined) {
            throw new Error('Required parameter backupiteration was null or undefined when calling restoreTask.');
        }
        if (taskid === null || taskid === undefined) {
            throw new Error('Required parameter taskid was null or undefined when calling restoreTask.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/backupiteration/${this.configuration.encodeParam({name: "backupiteration", value: backupiteration, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/task/${this.configuration.encodeParam({name: "taskid", value: taskid, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/restore`;
        return this.httpClient.request<RestoreTask200ResponseApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param backupAutoDeleteConfigApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAutoDelete(backupcollection: string, backupAutoDeleteConfigApiModel?: BackupAutoDeleteConfigApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public updateAutoDelete(backupcollection: string, backupAutoDeleteConfigApiModel?: BackupAutoDeleteConfigApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public updateAutoDelete(backupcollection: string, backupAutoDeleteConfigApiModel?: BackupAutoDeleteConfigApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public updateAutoDelete(backupcollection: string, backupAutoDeleteConfigApiModel?: BackupAutoDeleteConfigApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling updateAutoDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/autodelete`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: backupAutoDeleteConfigApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param podioCredentialsApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBackupCollectionCredentials(backupcollection: string, podioCredentialsApiModel?: PodioCredentialsApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PodioCredentialsValidationApiModel>;
    public updateBackupCollectionCredentials(backupcollection: string, podioCredentialsApiModel?: PodioCredentialsApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PodioCredentialsValidationApiModel>>;
    public updateBackupCollectionCredentials(backupcollection: string, podioCredentialsApiModel?: PodioCredentialsApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PodioCredentialsValidationApiModel>>;
    public updateBackupCollectionCredentials(backupcollection: string, podioCredentialsApiModel?: PodioCredentialsApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling updateBackupCollectionCredentials.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/credentials`;
        return this.httpClient.request<PodioCredentialsValidationApiModel>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: podioCredentialsApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param backupcollection 
     * @param recurrenceApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRecurrence(backupcollection: string, recurrenceApiModel?: RecurrenceApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public updateRecurrence(backupcollection: string, recurrenceApiModel?: RecurrenceApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public updateRecurrence(backupcollection: string, recurrenceApiModel?: RecurrenceApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public updateRecurrence(backupcollection: string, recurrenceApiModel?: RecurrenceApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (backupcollection === null || backupcollection === undefined) {
            throw new Error('Required parameter backupcollection was null or undefined when calling updateRecurrence.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (ApiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('ApiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('PhpBackupLoginSession', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/backupcollection/${this.configuration.encodeParam({name: "backupcollection", value: backupcollection, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/recurrence`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: recurrenceApiModel,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
